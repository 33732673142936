import { User, UserManager } from 'oidc-client-ts';

import { AuthRedirectState } from '@da-g3-acl/api-infra/auth/models';

import { AuthConfig } from './models/authConfig';

export async function authInit(config: AuthConfig): Promise<{ userManager: UserManager; user: User }> {
  const AccessTokenExpiry = 3600;
  const userManager = new UserManager({
    authority: config.authority,
    client_id: config.clientId,
    redirect_uri: config.redirectUri,
    accessTokenExpiringNotificationTimeInSeconds: AccessTokenExpiry / 2,
    response_mode: 'fragment',
  });

  if (window.location.hash.includes('code=') && window.location.hash.includes('state=')) {
    const signedInUser = await userManager.signinCallback();
    const state = signedInUser?.state as AuthRedirectState | undefined;
    window.location.replace(state?.originalPath ?? '/');
  }

  const user = await userManager.getUser();

  if (!user || user.expired) {
    const path = `${window.location.pathname}${window.location.search}`;
    await userManager.signinRedirect({
      state: {
        originalPath: path !== '/' ? path : undefined,
      },
    });
  }

  const authenticatedUser = user as User;

  return { user: authenticatedUser, userManager };
}
